export enum HttpStatus {
  T_200_OK = 200,
  T_204_NO_CONTENT = 204,
  T_400_BAD_REQUEST = 400,
  T_401_UNAUTHORIZED = 401,
  T_403_FORBIDDEN = 403,
  T_404_NOT_FOUND = 404,
  T_406_NOT_ACCEPTABLE = 406,
  T_408_REQUEST_TIMEOUT = 408,
  T_409_CONFLICT = 409,
  T_410_GONE = 410,
  T_412_PRECONDITION_FAILED = 412,
  T_422_UNPROCESSABLE_ENTITY = 422,
  T_423_LOCKED = 423,
  T_428_PRECONDITION_REQUIRED = 428,
  T_449_RETRY_LATER = 449,
  T_461_TOKEN_EXPIRED = 461,
  T_462 = 462,
  T_500 = 500,
  T_504_GATEWAY_TIMEOUT = 504,
  T_0_UNKNOWN_ERROR = 0
}
